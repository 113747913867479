<template>
  <!-- 概览 -->
  <div class="home-overview">
    <!-- 用户信息 -->
    <div class="user-meta">
      <div class="avatar">
        <img :src="require('/static/icons/header.png')" alt="" />
      </div>
      <div class="user-info">
        <p class="header">
          {{ userInfoData.companyName }}
          <span v-if="isBindCard" class="lable">已绑定银行卡</span>
          <span v-else class="lable-nobind">未绑定银行卡</span>
          <router-link
            v-if="!isBindCard"
            to="/user/account-bind/forms"
            style="color: #409eff; font-size: 14px; margin-left: 10px"
          >
            立即绑定
          </router-link>
          <!-- <a v-if="!isBindCard" style="color: #409EFF;font-size: 14px;margin-left: 10px;" to="/user/account-bind/forms">

          </a> -->
        </p>

        <Row>
          <Col span="8">
            <Row>
              <Col span="10"><span class="col">联系方式</span></Col>
              <Col span="14">
                <span class="col-text">
                  {{ userInfoData.tel }}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span="16">
            <Row>
              <Col span="4"><span class="col">地址</span></Col>
              <Col span="20">
                <span class="col-text">
                  {{ userInfoData.address }}
                </span>
              </Col>
            </Row>

            <!-- <span class="col">{{ userInfoData.address }}</span> -->
          </Col>
        </Row>
        <div class="edit">
          <Icon type="ios-create-outline" style="margin-left: 8px" />
          <a @click="edit">修改</a>
        </div>
      </div>
    </div>
    <div class="item">
      <Row>
        <Col span="8">
          <span class="desc">账户余额</span>
        </Col>
        <Col span="8">
          <span class="desc">可提现金额</span>
        </Col>
        <Col span="8">
          <span class="desc">冻结资金</span>
        </Col>
      </Row>
      <Row>
        <Col span="8">
          <span class="num">{{ info.balance }}</span>
          &nbsp;&nbsp;&nbsp;元
        </Col>
        <Col span="8">
          <span class="num">{{ info.cashBalance }}</span>
          &nbsp;&nbsp;&nbsp;元
        </Col>
        <Col span="8">
          <span class="num">{{ info.freezeBalance }}</span>
          &nbsp;&nbsp;&nbsp;元
        </Col>
      </Row>
    </div>
    <Modal v-model="modal" title="公司地址" @on-ok="ok" @on-cancel="cancel">
      <Input v-model="addressValue" placeholder="修改公司地址" />
    </Modal>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import VueEvent from '@/model/event'
import { ref } from 'vue'
export default {
  name: 'MemberHomeOverview',
  data () {
    return {
      info: {
        balance: 0,
				cashBalance: 0,
        freezeBalance: 0,
      },
      userInfoData: {},
      // accountList: [],
      isBindCard: false,
      modal: false,
			addressValue: ''
    }
  },
  created () {
    this.init()
    this.getBalance()
    this.getPcMemberMemberCenter()
    this.getBindBankCardList()
  },
  methods: {
    init () {},
    getBindBankCardList () {
      const params = {}
      this.$store
        .dispatch('accountMoney/GetBindBankCardList', params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.accountList = res.result

            if (res.result.length > 0) {
              this.isBindCard = true
            }
          }
        })
        .catch(() => {})
    },
    getPcMemberMemberCenter () {
      // 个人中心 会员中心数据
      this.$store
        .dispatch('PcMemberMemberCenter', {})
        .then((res) => {
          if (res.returnCode === '1') {
            this.userInfoData = res.result
            VueEvent.emit('orderInfoMitt', this.userInfoData)
          }
        })
        .catch(() => {})
    },
    getBalance () {
      this.$store
        .dispatch('accountMoney/GetBanlance', this.formValidate)
        .then((res) => {
          if (res.returnCode === '1') {
            this.info = res.result
          }
        })
        .catch(() => {})
    },
    edit () {
      this.modal = true
    },
    ok () {
      const postData = ref({
        address: this.addressValue,
      })
      this.$store
        .dispatch('PcMemberUpdateAddress', postData.value)
        .then((response) => {
          if (response.returnCode === '1') {
            this.$Notice.success({
              title: '地址修改成功',
            })
            this.getPcMemberMemberCenter()
          }
        })
        .catch(() => {})
    },
    cancel () {
      this.value = ''
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    const store = useStore()
    // 获取用户信息
    const userInfo = store.state.login.userInfo
    return {
      userInfo,
    }
  },
}
</script>
<style scoped lang="less">
.home-overview {
  display: flex;
  flex-flow: column;
  height: 240px;
  // background: url(../../../../assets/images/center-bg.png) no-repeat center /
  //   cover;
  background-image: linear-gradient(
    to bottom,
    #fff4f4,
    rgba(255, 248, 248, 0.31)
  );
  display: flex;
  .user-meta {
    flex: 1;
    display: flex;
    align-items: center;
    .user-info {
      width: 70%;
      // margin-left: 20px;
      .lable {
        background: #fff4e3;
        font-size: 11px;
        color: #dc9100;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        margin-top: 5px;
        border-radius: 4px;
        padding: 2px;
        width: 40px;
        height: 22px;
      }
      .lable-nobind {
        background: #f4f4f5;
        font-size: 11px;
        color: #909399;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        margin-top: 5px;
        border-radius: 4px;
        padding: 2px;
        width: 40px;
        height: 22px;
      }
      .header {
        padding-left: 26px;
        font-size: 18px;
        font-weight: normal;
        color: #333333;
      }
      .col {
        padding-left: 26px;
        font-size: 13px;
        font-weight: normal;
        color: #666666;
      }
      .col-text {
        // padding-left: 26px;
        font-size: 13px;
        font-weight: normal;
        color: #666666;
      }
      .edit {
        float: right;
        a {
          // padding-left: 26px;

          font-size: 12px;
          color: #409eff;
        }
      }
    }
    .avatar {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h4 {
      padding-left: 26px;
      font-size: 18px;
      font-weight: normal;
      color: #333333;
    }
  }
  .item {
    flex: 1;
    margin-left: 46px;
    .desc {
      color: #333333;
      font-size: 14px;
    }
    .num {
      color: #333333;
      font-size: 24px;
    }
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
  }
}
/deep/ .ivu-row {
  margin-top: 10px;
}
</style>
