<template>
  <div class="member-home">
    <MemberHomeOverview />
    <MemberHomePanel title="我的订单">
      <div class="item">
        <Row :gutter="40">
          <!-- {{ val }} -->
          <Col span="2">
            <span class="desc">全部</span>
          </Col>
          <Col v-for="val in memList" :key="val.orderStatus" span="3">
            <span class="desc">{{ val.orderStatus }}</span>
          </Col>
        </Row>
        <Row :gutter="40">
          <Col span="2">
            <div class="order" style="color: #d12b23">
              {{ totalSellMemStatus }}
            </div>
          </Col>
          <Col v-for="val in memList" :key="val.orderStatus" span="3">
            <span class="order">{{ val.statusNum }}</span>
          </Col>
        </Row>
      </div>
    </MemberHomePanel>
    <MemberHomePanelToPurchase title="我的提单">
      <div class="item">
        <Row :gutter="40">
          <!-- {{ val }} -->
          <Col span="2">
            <span class="desc">全部</span>
          </Col>
          <Col v-for="val in sellMemList" :key="val.orderStatus" span="3">
            <span class="desc">{{ val.orderStatus }}</span>
          </Col>
        </Row>
        <Row :gutter="40">
          <Col span="2">
            <div class="order" style="color: #d12b23">
              {{ totalMemStatus }}
            </div>
          </Col>
          <Col v-for="val in sellMemList" :key="val.orderStatus" span="3">
            <span class="order">{{ val.statusNum }}</span>
          </Col>
        </Row>
      </div>
    </MemberHomePanelToPurchase>
  </div>
</template>
<script>
import MemberHomeOverview from '@/views/member/home/components/MemberHomeOverview'
import MemberHomePanel from '@/views/member/home/components/MemberHomePanel'
import MemberHomePanelToPurchase from '@/views/member/home/components/MemberHomePanelToPurchase'

import VueEvent from '@/model/event'
export default {
  name: 'MemberHomePage',
  components: {
    MemberHomeOverview,
    MemberHomePanel,
    MemberHomePanelToPurchase,
  },
  data () {
    return {
      memList: [],
      sellMemList: [],
      totalSellMemStatus: 0, // 我的订单总数
      totalMemStatus: 0, // 我的提单单总数
    }
  },
  created () {},
  mounted () {
    this.OrderInfoMitt()
  },
  methods: {
    OrderInfoMitt () {
      const arr = []
      const arr1 = []
      VueEvent.on('orderInfoMitt', (value) => {
        if (value) {
          this.totalSellMemStatus = value.totalSellMemStatus
          this.totalMemStatus = value.totalMemStatus
          value.sellPaymentStatusNum.forEach((element) => {
            switch (element.orderStatus) {
              case '1':
                arr.push({
                  orderStatus: '待确认',
                  statusNum: Number(element.statusNum),
                })
                break
              case '2':
                arr.push({
                  orderStatus: '待签合同',
                  statusNum: Number(element.statusNum),
                })
                break
              case '3':
                arr.push({
                  orderStatus: '待付款',
                  statusNum: Number(element.statusNum),
                })
                break
              case '4':
                arr.push({
                  orderStatus: '待补仓单',
                  statusNum: Number(element.statusNum),
                })
                break
              case '7':
                arr.unshift({
                  orderStatus: '待付保证金',
                  statusNum: Number(element.statusNum),
                })
                break
              case '100':
                arr.push({
                  orderStatus: '已完成',
                  statusNum: Number(element.statusNum),
                })
                break
              case '1052':
                arr.push({
                  orderStatus: '已取消',
                  statusNum: Number(element.statusNum),
                })
                break
              default:
                break
            }
          })
          this.memList = arr
          value.paymentStatusNum.forEach((element) => {
            switch (element.orderStatus) {
              case '1':
                arr1.push({
                  orderStatus: '待确认',
                  statusNum: Number(element.statusNum),
                })
                break
              case '2':
                arr1.push({
                  orderStatus: '待签合同',
                  statusNum: Number(element.statusNum),
                })
                break
              case '3':
                arr1.push({
                  orderStatus: '待付款',
                  statusNum: Number(element.statusNum),
                })
                break
              case '4':
                arr1.push({
                  orderStatus: '待补仓单',
                  statusNum: Number(element.statusNum),
                })
                break
              case '7':
                arr1.unshift({
                  orderStatus: '待付保证金',
                  statusNum: Number(element.statusNum),
                })
                break
              case '100':
                arr1.push({
                  orderStatus: '已完成',
                  statusNum: Number(element.statusNum),
                })
                break
              case '1052':
                arr1.push({
                  orderStatus: '已取消',
                  statusNum: Number(element.statusNum),
                })
                break
              default:
                break
            }
          })
          this.sellMemList = arr1
        }
      })
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {},
}
</script>
<style scoped lang="less">
.member-home {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
.item {
  flex: 1;
  margin-left: 46px;
  .desc {
    color: #333333;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .num {
    color: #333333;
    font-size: 24px;
  }
  .order {
    color: #333333;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
}
</style>
